.user_management {
    background-color: white;
    min-height: 400px;
    padding: 25px;
    margin-top: 50px;
  }
.user_management.dark {
    background-color: #000;
  }
  .user_management_container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
.user_management .main_title {
    border-left: 10px solid #005daa;
    color: #005daa;
    font-weight: bold;
    font-size: 28px;
    padding-left: 10px;
    margin-bottom: 25px;
}
.user_management_button {
    width: fit-content;
    height: 50px;
    border-radius: 8px;
  
    color: #fff;
    cursor: pointer;
  }
.user_management_button button {
    background-color: #005daa;
    border: none;
  }
.user_management_button button:hover,
.user_management_button button:active,
  .user_management_button button:focus {
    background-color: #005daa;
  }
  .user_management_table {
    margin-top: 20px;
    /* border: 1px solid #dddddd; */
    /* padding: 25px; */
    background-color: #fff;
  }
  .user_management_table.dark {
    background-color: #000;
  }
  .user_management_table.dark .table {
    --bs-table-hover-color: #fff;
    color: #fff;
  }
  .user_management_table table thead {
    border-top: 1px solid #dddddd !important;
  }
  .user_management_table table tr {
    border-bottom: 1px solid #dddddd !important;
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    .user_management .main_title {
        font-size: 18px;
      }
      .user_management_container_header {
        flex-direction: column;
      }
  }