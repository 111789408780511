/* Chivo */

@font-face {
  font-family: "Chivo";
  src: local("Chivo"), url(./assets/fonts/Chivo-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Chivo";
  src: local("Chivo"), url(./assets/fonts/Chivo-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Chivo";
  src: local("Chivo"), url(./assets/fonts/Chivo-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Chivo";
  src: local("Chivo"), url(./assets/fonts/Chivo-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Chivo";
  src: local("Chivo"), url(./assets/fonts/Chivo-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Chivo";
  src: local("Chivo"), url(./assets/fonts/Chivo-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* Graphik */

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikThin.otf) format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikThinItalic.otf) format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikExtralight.otf) format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikExtralightItalic.otf) format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikLight.otf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikLightItalic.otf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikRegularItalic.otf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikRegular.otf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikMedium.otf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikMediumItalic.otf) format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikSemibold.otf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikSemiboldItalic.otf) format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikBold.otf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikBoldItalic.otf) format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikBlack.otf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikBlackItalic.otf) format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikSuper.otf) format("truetype");
  font-weight: 950;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikSuperItalic.otf) format("truetype");
  font-weight: 950;
  font-style: italic;
}

/* @font-face {
  font-family: "Chivo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chivo/v12/va9I4kzIxd1KFrBmQeNVkqDOeTY.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
} */
/* latin */
/* @font-face {
  font-family: "Chivo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chivo/v12/va9I4kzIxd1KFrBoQeNVkqDO.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/Graphik-Regular.woff) format("woff");
}
*/

body {
  /* overflow: hidden; */
  overflow-x: hidden;
}

.root-light {
  background-color: #e5e5e5 !important;
}

.root-dark {
  background-color: #0b0b0b !important;
}
.pdf-docs {
  position: fixed;
  z-index: 999999;

  left: 50%;
  transform: translateX(-50%);
}
.pdf-docs .react-pdf__Document .react-pdf__Page__canvas{
  width: 100% !important;
  height: 100% !important;

}

.pdf-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
}
.pdf-currentPageNumber{ 
  font-weight: 500;
  font-size: 14px;
}
.right-side-pdf-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}
.move_fun {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* loading modal */
.loader .modal-dialog{
  display: table;
  position: relative;
  margin: 0 auto;
  top: calc(50% - 24px);
}

.loader .modal-dialog .modal-content{
  background-color: transparent;
  border: none;
}

.spinner-border {
    color: #35B2E9;
}


/* @media (max-width: 620px) {
.pdf-docs .react-pdf__Document .react-pdf__Page__canvas{
  width: 100% !important;
  height: 100% !important;
}
} */
/* .pdf-docs .react-pdf__Document {
  width: 300px !important;
} */
/* .react-pdf__Page__canvas {
  margin: 0 auto;
  width: auto !important;
  height: auto !important;
  overflow: scroll;
} */
