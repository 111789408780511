.dark {
  color: #FFFFFF !important;
}

.notis-blk {
  max-height: 450px;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF !important;
}

.notis-blk-dark {
  max-height: 450px;
  border: 1px solid #DDDDDD;
  background-color: #242424 !important;
}

.noti-main-blk {
  cursor: grab;
}

.noti-main-blk-dark {
  background-color: #242424 !important;
  cursor: grab;
}

.noti-sub-blk {
  padding-top: 20px;
}

.noti-blk {
  display: table;
}

.noti-cell-blk {
  display: table-cell;
}

.noti-padding-blk {
  background-color: #E2053F;
  width: 8px;
  height: 33px;
}

.noti-blk-header {
  vertical-align: middle;
  padding-left: 0.5em;
  color: #E2053F;
  font-weight: 600;
}

.notis-blk .settings-icon, .notis-blk-dark .settings-icon {
  float: right;
  cursor: pointer;
  padding-top: 0.5em;
  padding-right: 10px;
}

.notis-blk-dark .noti-info {
  color: #FFFFFF;
}

.noti-noti {
  max-height: 340px;
  padding-top: 5px;
  display: table !important;
}

.noti-blk-title {
  font-weight: bold;
  float: left;
  display: inline-block;
  max-width: 90%;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 15px;
  color: #005DAA;
  padding-top: 2em;
  padding-bottom: 1em;
}

.noti-info {
  color: #E2053F;
  min-height: 40px;
}

.noti-info p {
  font-size: 14px;
}

.read-noti {
  cursor: pointer;
  font-size: 12px;
}

.notis-blk-dark .noti-blk-title {
  color: #E2053F !important;
}

.notis-bllk .noti-blk-titlle {
  color: #005DAA !important;
}
