.mid-chart {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.tile.drag-over {
	border: 2px dashed #000;
	background-color: rgba(0, 0, 0, 0.1);
	transition: all 250ms;
}

.tile.drag-source {
	opacity: 0.4;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	background-color: rgba(145, 200, 248, 0.75);
	transform: scale(0.9);
	transition: all 250ms;
}

.dashboard div.row {
  padding-right: 0px;
}

.dashboard .blank-blk, .extra-blank .blank-blk {
  height: 150px;
}

.extra-blank .blank-blk {
  margin-left: 0px;
  margin-right: 0px;
}

.extra-blank {
  padding-left: 10px !important;
  padding-right: 23px !important;
  width: 100%;
  margin-left: 5px !important;
}

.modal-dialog {
  max-width: 800px !important;
}

.modal-padding-blk {
  background-color: #005DAA;
  width: 8px;
  height: 33px;
  display: inline-block;
}

.modal-title-blk {
  display: inline-block;
  height: 40px;
  vertical-align: middle;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  font-weight: "Chivo" !important;
}

.modal-title-blk span {
  color: #005DAA;
  padding-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  font-family: "Graphik" !important;
  line-height: 30.8px;
}

.modal-header {
  border-bottom: 0px !important;
}

.add-tile-modal {
  height: 120px;
  padding: 10px;
}

.add-tile-modal-child {
  border: 1px solid #DDDDDD;
  height: 100%;
  transition: 0.1s;
}

.add-tile-modal-child:hover {
  border: 1px solid #DDDDDD;
  height: 100%;
  background-color: #005DAA !important;
  color: #FFFFFF;
}

.add-tile-img-holder {
  transition: 0.1s;
  text-align: center;
  margin: 25px;
}

.tile-menu-title {
  line-height: 1.5em;
  font-family: "Chivo" !important;
  font-size: 18px;
  font-weight: 400;
}

.dark {
  color: #FFFFFF !important;
}

.dark-blk {
  background-color: #0B0B0B !important;
}

#liveStatsBlkModal .dark-blk.modal-body div {
  background-color: #424242 !important;
}

.dark-blk.modal-body .add-tile-modal-child {
  background-color: #424242;
}

.dark.dark-blk .btn-close {
  background: url(../../assets/images/live/close-dark.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.paging-space-blk {
  display: inline !important;
}

.spacer {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.spacer .space-floor-blk {
  height: 80px;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .mid-chart {
    padding: 0.7em !important;
  }
  .modal-title-blk span {
    font-size: 18px !important;
  }
  .modal-padding-blk {
    width: 5px !important;
    height: 23px !important;
  }
  .add-space-type-dashboard {
    font-size: 15px !important;
  }
  .space-blk-name {
    font-size: 12px !important;
  }
  .spacer .space-floor-blk {
    height: 80px !important;
  }
  .settings-icon {
    margin-top: -0.3em;
  }
  .tile-menu-title {
    font-size: 14px !important;
  }
}


.add-space-type-dashboard {
  display: inline-block;
  border: 1px solid #dddddd !important;
  margin-right: 10px;
  color: #373737;
  cursor: pointer;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.add-space-type-dashboard.active:hover {
  color: #ffffff !important;
}

.add-space-type-dashboard.active {
  background-color: #005daa !important;
  color: #ffffff !important;
}

.add-space-type-dashboard p {
  font-size: 15px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
}