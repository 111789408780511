.stats-blk .padding-blk, .stats-blk-dark .padding-blk {
  background-color: #005DAA;
  width: 8px;
  height: 33px;
}

.inline-blk {
  display: inline-block;
}

.main-blk-dark {
  background-color: #242424 !important;
}

.stats-blk {
  min-height: 294px;
  padding-top: 20px;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF !important;
}

.stats-blk-dark {
  min-height: 294px;
  padding-top: 20px;
  border: 1px solid #DDDDDD;
  background-color: #242424 !important;
}

.stats-blk .table-blk, .stats-blk-dark .table-blk {
  display: table;
  padding-left: 0px;
}

.table-cell-blk {
  display: table-cell;
}

.stats-blk .blk-header, .stats-blk-dark .blk-header {
  vertical-align: middle;
  padding-left: 0.5em;
  color: #005DAA;
  font-weight: 600;
  font-size: 28px;
  font-style: normal;
  line-height: 31px;
}

.dark {
  color: #FFFFFF !important;
}

.settings-icon {
  float: right;
  cursor: pointer;
  padding-top: 0.3em;
}

.stats-blk .liveStatsStarBlk, .stats-blk-dark .liveStatsStarBlk {
  text-align: center;
  padding-top: 3em;
  padding-bottom: 1em;
}

.liveStatsStar {
  margin-bottom: 1.5em;
  border-right: 1px solid #DDDDDD;
}

.liveStatsStarMobile {
  margin-bottom: 0.5em;
}

.cdn-blk {
  display: inline-block;
  /* text-align: center; */
}

.stats-blk .cdn-blk-parent {
  background-color: #F8F8F8;
  margin: 1.5em !important;
  padding: 1em;
}

.stats-blk-dark .cdn-blk-parent-dark {
  background-color: #424242;
  margin: 1.5em !important;
  padding: 1em;
}

.cdn-blk-text-graphik {
  color: #005DAA;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 31px;
  font-family: "Graphik" !important;
  margin-top: -0.7em;
}

.stats-blk .cdn-blk-text, .stats-blk-dark .cdn-blk-text {
  color: #005DAA;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  font-family: "Chivo" !important;
  margin-top: -0.7em;
}

.cdn-blk-text-cooling {
  margin-top: -0.5em;
}

.liveStatsStarImg {
  height: 50px !important;
}

.stats-blk .cdn-blk-title, .stats-blk-dark .cdn-blk-title {
  height: 65px;
  font-weight: bold;
}

.stats-blk .cdn-blk-title h5, .stats-blk-dark .cdn-blk-title h5 {
  font-family: "Chivo" !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.stats-blk .cdn-blk-title-mobile h5, .stats-blk-dark .cdn-blk-title-mobile h5 {
  font-family: "Chivo" !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}

.cdn-blk-title-left-div, .cdn-blk-title-right-div {
  display: inline-block;
}

.cdn-blk-title-left-div {
  width: 25% !important;
  float: left !important;
  padding-left: 1em !important;
}

.cdn-blk-title-right-div {
  width: 100% !important;
}

.cdn-blk-title-left-div h5 {
  font-weight: bold;
  font-family: "Chivo" !important;
}

.cdn-blk-title-right-div {
  float: right;
  padding-right: 1em;
}

.liveStatsStarBlk .cdn-blk-title-right {
  font-weight: bold;
  text-align: left;
  padding-left: 1em;
  display: inline-block;
  font-family: "Chivo" !important;
  text-overflow: ellipsis;
  overflow: hidden;
  /* width: calc(100% - 24px); */
  font-size: 18px;
  float: left;
}

#liveStatsRooms {
  float: left;
  padding-right: 5px;
  padding-left: 5px;
  color: #373737;
}

.right-img {
  height: 50px;
}

.custom-tooltip, .custom-tooltip-dark {
  font-size: 12px !important;
  font-family: "Chivo" !important;
}

.custom-tooltip-dark > .tooltip-inner {
  background-color: #C4C4C4;
  color: #0B0B0B;
  font-family: 'Chivo';
}

.stats-blk .text-be-float, .stats-blk-dark .text-be-float {
  font-size: 12px;
  vertical-align: text-top;
  font-weight: 700;
}

.text-be-float sup {
  font-size: 12px;
}

.sub-header1 {
  padding-top: 1em;
}

.sub-header1 h5 {
  padding-left: 2.3em;
  font-weight: bold;
  font-family: "Chivo" !important;
}

/* .add-border-right {
  border-right: 1px solid #DDDDDD !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
} */

.add-border-none {
  border-right: 0px !important;
}

.liveStatsStarMobile {
  display: inline-block !important;
  vertical-align: middle;
}

.cdn-blk-title-mobile {
  float: left;
  text-align: left;
  width: 100%;
}

.cdn-blk-title-mobile h5 {
  display: inline-block;
  font-size: 14px;
}

.liveStatsModalOpener {
  vertical-align: super;
  cursor: pointer;
  float: right;
}

.mobile-hr {
  width: 95% !important;
  margin-left: 1em !important;
}

.liveModalTitle {
  font-size: 12px;
  font-family: "Chivo" !important;
  font-weight: 700;
}

#liveStatsBlkModal {
  top: 35vh !important;
}

@media (min-width: 992px) {
  .liveStatsStar:nth-of-type(3) {
    border-right: 1px solid #DDDDDD;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .liveStatsStar {
    border-right: 1px solid #DDDDDD;
  }
}

@media (max-width: 767px) {
  .liveStatsStar {
    border-right: 0px;
  }
}

@media (max-width: 502px) {
  .cdn-blk-title-right {
    padding-left: 0.5em;
  }
}

@media (max-width: 767px) {
  .cdn-blk-title {
    height: 30px !important;
  }
  .cdn-blk-text {
    margin-bottom: 1em;
  }
}

@media (max-width: 767.98px) {
  .liveStatsStarImg {
    height: 40px !important;
  }
  #indicator2 {
    width: 25px;
  }
  .cdn-blk-text-graphik {
    font-size: 18px;
  }
  .cdn-blk-text-cooling {
    margin-top: -2em !important;
    font-size: 18px !important;
  }
  .chosen_room {
    font-size: 15px !important;
    max-width: calc(100% - 24px);
  }
  .liveStatsStarBlk .cdn-blk-text {
    font-size: 18px !important;
  }
  .stats-blk .cdn-blk-parent, .stats-blk-dark .cdn-blk-parent-dark {
    margin: 1em !important;
    padding-top: 1.5em !important;
    padding-bottom: 0.5em !important;
  }
}
